.advantages {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
    gap: 32px;
}

.advantages__element {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.advantages__element img {
    background-color: #10316B;
    padding: 30px;
    border-radius: 25px;
    margin: 0 auto;
}

.advantages__element h2 {
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    min-height: 66px;
}

.advantages__element p {
    /* Text/Regular/Normal */
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

@media (max-width: 800px) {
    .advantages {
        flex-direction: column;
        align-items: center;
        gap: 60px;
    }
    .advantages__element {
        max-width: 100%;
    }
    .advantages__element h2 {
        font-size: 32px;
    }
    .advantages__element p {
        font-size: 18px;
        text-align: center;
    }
}