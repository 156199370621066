.privacy {
    width: 100%;
    height: 100%;
}

.privacy {
    padding: 120px 0;
}

.privacy h1, .privacy h2, .privacy h3 {
    margin-top: 30px !important;
}

.privacy h1 {
    font-size: 48px;
}
.privacy h2 {
    font-size: 32px;
}
.privacy h3 {
    font-size: 28px;
}

.privacy ul {
    list-style: circle;
    padding-left: 50px;
}

.privacy h2,
.privacy p {
    margin: 5px 0;
    line-height: 1.5;
}