.about {
    background: linear-gradient(180deg, #F4F4F4 0%, rgba(128, 128, 128, 0.00) 100%);
}

.about__element {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    padding: 64px 0;
}

.about__element>* {
    width: 50%;
}

.about__element--left {
    flex-direction: row-reverse;
}

.about__content {
    display: flex;
    align-self: center;
    flex-direction: column;
}

.about__picture {
    position: relative;
}

.about__picture,
.about__content {
    max-width: 50%;
}

.about__picture img {
    border-radius: 50px;
    max-height: 750px;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.about__picture svg {
    position: absolute;
    top: -15px;
    right: -15px;
}

.about__content h3 {
    color: #10316B;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    /* 57.6px */
}

.about__content h3>span {
    color: var(--Black, #000);
    font-weight: 700;
}

.about__button {
    margin: 32px auto 0 0;
    display: inline-block;
    border-radius: 30px;
    background: #10316B;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--White, #FFF);
    cursor: pointer;
    transition: 0.3s;

    /* Text/Regular/Normal */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

.about__button:hover {
    background: #000054;
}

.mini-header {
    color: black;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 27px */
    font-variant: all-small-caps;
    padding-bottom: 5px;
}

.about__content p {
    color: black;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-top: 25px;
}



/* Numbers section */
.numbers {
    margin: 60px 0;
    border-radius: 50px;
    background: linear-gradient(93deg, #10316B 1.71%, #003 98.02%);
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    padding: 45px 70px;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.numbers__element {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.numbers__element-icon {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 91px */
}

.numbers__element h4 {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 41.6px */
}

.numbers__element-paragraph {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

@media (max-width: 800px) {
    .about__element {
        flex-direction: column;
        align-items: center;
        gap: 32px;
        padding: 32px 0;
    }

    .about__element>* {
        width: 100%;
    }

    .numbers {
        flex-direction: column;
        gap: 64px;
        padding: 80px;
    }

    .numbers__element-icon {
        font-size: 100px;
    }

    .numbers__element h4 {
        font-size: 38px;
    }

    .numbers__element-paragraph {
        font-size: 20px;
        color: #B3B3E0;
    }

    .about__picture,
    .about__content {
        max-width: 90%;
    }

    .about__content h3 {
        font-size: 40px;
        text-align: center;
    }

    .about__content h3>br {
        display: none;
    }

    .about__content p {
        font-size: 20px;
    }
}