.footer {
    background: linear-gradient(93deg, #10316B 1.71%, #003 98.02%);
    padding: 80px 0;
}

.footer__content {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
}

.footer__column {
    display: flex;
    flex-direction: column;
    color: white;
}

.footer__column a {
    cursor: pointer;
    min-width: 180px;
}

.footer__column a:hover {
    text-decoration: underline;
}

.footer__column--double {
    display: flex;
    flex-direction: row !important;
    gap: 24px;
}

.footer__column--left > p {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
}

.footer__column--left > a {
    text-decoration: underline !important;
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.footer__media {
    display: flex;
    gap: 15px;
    margin-top: 24px;
}

.footer__media > a {
    min-width: auto;
}

.footer__copyright {
    border-top: 1px white solid;
    display: flex;
    justify-content: space-between;
}

.footer__copyright p, .footer__copyright a {
    margin-top: 32px;
    color: rgba(255, 255, 255, 0.5);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.footer__copyright a > span {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 800px) {
    .footer__column a {
        min-width: auto;
    }

    .footer__copyright {
        display: block;
        text-align: center;
    }


    .footer__media {
        margin: 24px auto 0 auto;
    }
    .footer__media img {
        width: 50px;
    }

    .footer__content {
        display: block;
        text-align: center;
    }

    .footer__column--double {
        display: block;
        margin-top: 50px;
    }
}