.contact {
    margin-top: 112px;
    position: relative;
}

.contact__title h4, .contact__form--submited h4 {
    color: var(--Black, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    margin-bottom: 24px;
}

.contact__title h4 > span {
    color: #10316B;
    font-weight: 900;
}

.contact__title p, .contact__form--submited p {
    color: var(--Black, #000);
    margin: 0 auto;
    text-align: center;
    max-width: 800px;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

.contact__form {
    margin: 24px auto 0 auto;
    position: relative;
    z-index: 200;
}

.contact__form--submited {
    min-height: 740px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.contact__form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 560px;
    margin: 0 auto;
}

label {
    margin: 24px auto 0 0;
    color: var(--Black, #000);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.contact__terms-label {
    margin: 0 !important;
}
.contact__terms-label > a {
    text-decoration: underline;
    color: #10316B;
}

.contact__form  input, textarea {
    height: 48px;
    padding: 12px;
    border-radius: 15px;
    border: 1px solid var(--Black, #000);
    background: var(--White, #FFF);
}

.contact__terms {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 24px 0;
}

#name, #brief, #email {
    width: 560px;
}

#brief {
    height: 144px;
}

#terms {
    height: auto !important;
    margin: auto 15px;
}

#submit {
    background: #10316B;
    display: flex;
    padding: 12px 35px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: var(--White, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    cursor: pointer;
    transition: 0.3s;
}

#submit:hover {
    background-color: #000054;
}

.contact__decorCircle-1, .contact__decorCircle-2, .contact__decorCircle-3 {
    position: absolute;
    z-index: 100;
}

.contact__decorCircle-1 {
    width: 341px;
    height: 341px;
    border-radius: 341px;
    border: 2px dashed #10316B;
    top: 50%;
    left: 60%;
}

.contact__decorCircle-2 {
    width: 97px;
    height: 97px;
    border: 4px dashed #10316B !important;
    border-radius: 100%;
    top: 20%;
    left: 80%;
}

.contact__decorCircle-3 {
    width: 73px;
    height: 73px;
    transform: rotate(-45deg);
    border: 1px dashed #10316B !important;
    border-radius: 100%;
    top: 55%;
    left: 90%;
}

.contact__contacts {
    display: flex;
    justify-content: space-around;
    margin: 112px auto;
}

.contact__contacts-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.contact__contacts-element h4 {
    color: var(--Black, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
}

.contact__contacts-element p {
    color: var(--Black, #000);
    text-align: center;
    font-family: Roboto;
    max-width: 600px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.contact__contacts-element a {
    text-decoration: underline;
}

@media (max-width: 800px) {
    .contact__contacts {
        flex-direction: column;
        gap: 50px;
    }
    .contact__title p {
        max-width: 90%;
        font-size: 18px;
    }
    .contact__form form {
        max-width: 90%;
    }
    #name, #brief, #email {
        width: 100%;
    }
    .contact__decorCircle-3 {
        top: 45%;
        left: 15%;
    }
    .contact__contacts-element p {
        max-width: 90%;
        font-size: 18px;
    }
    .contact__contacts-element a {
        font-size: 18px;
    }
    .contact__contacts-element {
        gap: 20px;
    }
    label {
        font-size: 18px;
    }
    #submit {
        font-size: 20px;
        height: auto;
    }
}