.materialsArticle {
    padding: 100px 0;
    max-width: 1200px;
    margin: 0 auto;
}
.materialsArticle > h1 {
    margin: 30px 0;
    font-size: 42px;
}
.materialsArticle > h2 {
    margin: 20px 0;
    font-size: 36px;
}
.materialsArticle li {
    list-style-type: circle;
    margin: 5px 0;
}

@media (max-width: 800px) {
    .materialsArticle > h1 {
        font-size: 32px;
        line-height: 1;
    }
    .materialsArticle > h2 {
        font-size: 28px;
        line-height: 1;
    }
    .materialsArticle li {
        max-width: 90%;
        margin: 15px auto 0 auto;
    }
}