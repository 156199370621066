.hero {
    height: 800px;
    background-image: url(../assets/img/hero2.jpeg);
    /* background-size: 130%;
    background-position: 0% 40%; */
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 3%, rgba(0, 0, 0, 0.00) 100%), url(../assets/img/hero2.jpeg), lightgray -275.11px -870.849px / 122.654% 284.352% no-repeat;
    background-blend-mode: normal, darken;
    background-size: 100%;
    background-position: -100% 60%;
    /* background-attachment: fixed; */
}

.hero>.container {
    padding-top: 15%;
}

.hero h1,
.hero p {
    max-width: 700px;
}

.hero>.container * {
    margin-bottom: 50px;
}

.hero h1 {
    opacity: 90%;
    color: #fff;
    font-family: Roboto;
    font-size: 72px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    /* 86.4px */
}

.hero p {
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
}

.hero a {
    display: inline-block;
    border-radius: 30px;
    background: #10316B;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--White, #FFF);
    cursor: pointer;
    transition: 0.3s;

    /* Text/Regular/Normal */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

.hero a:hover {
    background: #000054;
}

/* Extra small devices */
@media (max-width: 800px) {
    .hero {
        /* background-position: 85% 35%;
        height: 800px;
        background-size: 300%; */
        background-position: 65% 35%;
        height: 90vh;
        /* background-attachment: revert; */
        background-size: 250% !important;
        background-attachment: scroll;
        /* background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 5%, rgba(0, 0, 0, 0.00) 100%), url(../assets/img/hero2.jpeg), lightgray -275.11px -870.849px / 122.654% 284.352% no-repeat; */
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) 5%, rgba(0, 0, 0, 0.00) 100%), url(../assets/img/hero2.jpeg), lightgray -275.11px -870.849px / 122.654% 284.352% no-repeat;
    }

    .hero h1 {
        font-size: 42px;
    }

    .hero p,
    .hero a {
        font-size: 18px;
    }

    .hero>.container * {
        margin-bottom: 42px;
    }

    .hero>.container {
        padding-top: 25%;
        text-align: center;
    }
}

/* Wide screens */
@media (min-width: 1400px) {
    .hero h1 {
        font-size: 82px;
    }
}